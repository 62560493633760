





























































































































import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
import {
	KCardHeader,
	KCardHeaderBtn,
	KSpinner,
	KCardFooter,
	KCardFooterBtn,
} from "@kasasa/fbase-components";
import {
	AuthGroupManager,
	Dialog,
	Alert,
	NoticeClass,
	NoticeResponse,
	KCrumb,
	KInputValidate,
} from "@kasasa/fbase-components/lib";
import { namespace } from "vuex-class";

import AssetService from "@/services/AssetService";
import TagService from "@/services/TagService";

import { GroupPerms } from "@/store";
import { Asset, AssetContentType, AssetFactory, Tag } from "@/services/api";
import { RouteName, Mode } from "@/router";
import { Route, RawLocation, NavigationGuardNext } from "vue-router";
import AssetDetails from "@/components/AssetDetails.vue";
import AssetForm from "@/components/form/AssetForm.vue";
import { KFileUpload } from '@kasasa/fbase-components';
import HIRT from "@/components/hirt";
import Compressor from 'compressorjs';

const auth = namespace("auth");
const imageParseError = 'Unable to parse image. Please upload a valid image.';

@Component({
	components: {
		KSpinner,
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		KFileUpload,
		AssetDetails,
		AssetForm,
	},
})
export default class AssetDetailsPage extends Vue {
	@Prop() clientId!: number;
	@Prop() assetId!: number;
	@Prop({ default: "" }) readonly mode!: string;
	@Prop({ default: false }) readonly isGlobal!: boolean;

	@auth.State authManager!: AuthGroupManager;

	isLoaded = false;

	assetSvc = new AssetService(this.$store);
	tagSvc = new TagService(this.$store);

	asset = AssetFactory(this.clientId);

	clientTags: Tag[] =[]; 

	loadData = "";
	originalFileName = "";
	saveDisabled = true;
	maxFileSizeMb = 6;
	fileUploadErrorMessages: string[] = [];

	get ro(): boolean {
		return !this.authManager?.canWrite(GroupPerms.MAIN_PERM);
	}

	get modeDetails(): boolean {
		return this.mode === Mode.DETAILS;
	}

	get modeDetailsRW(): boolean {
		return !this.ro && this.mode === Mode.DETAILS;
	}

	get modeEdit(): boolean {
		return !this.ro && this.mode === Mode.EDIT;
	}

	get modeAdd(): boolean {
		return !this.ro && this.mode === Mode.ADD;
	}

	mounted(): void {
		this.saveDisabled = this.modeAdd;
	}

	get fileUploadRules(): { (value: File | File[] | null): boolean | string }[] {
		return [
			KInputValidate.fileMaxSizeMb(
				this.maxFileSizeMb,
				`File exceeds ${this.maxFileSizeMb}MB. Please upload a different file or reduce file size.`
			),
		];
	}
	@Watch("$route", { immediate: true, deep: true })
	load(): void {
		switch (this.mode) {
			case Mode.ADD:
				this.asset = AssetFactory(this.clientId);
				this.isLoaded = true;
				// store initial state
				this.setLoadedData(this.asset);
				break;
			default:
				this.loadAsset();
		}
		if (this.modeAdd || this.modeEdit ) {
			this.tagSvc.findClientTags(this.clientId).then((resp) => {
				this.clientTags = resp.data.data;
			});
		}
	}

	setLoadedData(data: Asset): void {
		this.loadData = JSON.stringify(data);
	}

	loadAsset(): void {
		this.assetSvc.find(this.clientId, this.assetId).then((resp) => {
			this.asset = resp.data.data;
			this.isLoaded = true;
			this.setLoadedData(this.asset);
		});
	}

	get isDirty(): boolean {
		return this.loadData !== JSON.stringify(this.asset);
	}

	get title(): string {
		let title = "";
		switch (this.mode) {
			case Mode.ADD:
				title = "Add New Media";
				break;
			case Mode.DETAILS:
				title = "Media Details";
				break;
			case Mode.EDIT:
				title = "Edit Metadata";
				break;
		}
		return title;
	}

	get crumbs(): KCrumb[] {
		let routeName = RouteName.CLIENT_HOME;
		if (this.isGlobal) {
			routeName = RouteName.GLOBAL_HOME;
		}
		const crumbs = [] as KCrumb[];
		crumbs.push({
			key: "0",
			text: (this.isGlobal ? "Kasasa " : "") + "Media Library",
			disabled: false,
			link: true,
			exact: true,
			to: { name: routeName, params: this.$route.params },
		});

		switch (this.mode) {
			case Mode.ADD:
				crumbs.push({
					key: "1",
					text: "Add New Media",
					disabled: true,
				});
				break;
			case Mode.DETAILS:
				crumbs.push({
					key: "2",
					text: this.title,
					disabled: true,
				});
				break;
			case Mode.EDIT:
				routeName = RouteName.CLIENT_DETAIL;
				if (this.isGlobal) {
					routeName = RouteName.GLOBAL_DETAIL;
				}
				crumbs.push(
					{
						key: "1",
						text: "Media Details",
						link: true,
						exact: true,
						to: { name: routeName, params: this.$route.params },
					},
					{
						key: "2",
						text: this.title,
						disabled: true,
					}
				);
				break;
		}

		return crumbs;
	}

	get assetForm(): AssetForm & HIRT {
		return this.modeAdd
			? (this.$refs.assetFormAdd as AssetForm & HIRT)
			: (this.$refs.assetFormEdit as AssetForm & HIRT);
	}

	onFileSelected(file: File): void {
		// reset
		this.fileUploadErrorMessages = [];
		this.asset.fileName = "";
		this.asset.fullAssetUrl = "";
		this.asset.contentType = "";
		this.asset.binaryData = "";

		if (file) {
			this.originalFileName = file.name.replaceAll(' ', '-');
			this.asset.fileName = this.originalFileName;
			this.asset.fullAssetUrl = URL.createObjectURL(file);

			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				this.updateAssetImageFromFileReader(reader);

				// eslint-disable-next-line @typescript-eslint/no-this-alias
				var self = this;
				new Compressor(file, {
					quality: 0.8,
					success(blob) {
						// ignoring gif as compressor strips out animation, just using as image verification
						if (self.asset.contentType !== AssetContentType.GIF) {
							reader.readAsDataURL(blob); 
							// not updating file upload size in UI
							reader.onload = () => self.updateAssetImageFromFileReader(reader);
						}
					},
					error() {
						self.fileUploadErrorMessages = [imageParseError];
					}
				});
			};
		}

		(this.$refs.assetDetails as AssetDetails).loadImage();
		this.saveDisabled = !file;
	}

	updateAssetImageFromFileReader(reader: FileReader): void {
		if (typeof reader.result !== 'string') {
			this.fileUploadErrorMessages = [imageParseError];
			return;
		}

		const match = (reader.result as string).split(",");
		if (match.length !== 2) {
			this.fileUploadErrorMessages = [imageParseError];
			return;
		}

		this.asset.contentType = match[0]
			.replace("data:", "")
			.replace(";base64", "");
		this.asset.binaryData = match[1];
	}

	cancelAction(): void {
		// beforeRouteLeave() will detect unsaved changes
		this.goToDetails();
	}

	closeAction(): void {
		this.goToList();
	}

	goToList(): void {
		let routeName = this.isGlobal
			? RouteName.GLOBAL_HOME
			: RouteName.CLIENT_HOME;
		const route = {
			params: this.$route.params,
			name: routeName,
		} as RawLocation;
		this.$router.push(route);
	}

	goToDetails(): void {
		let routeName = this.isGlobal
			? RouteName.GLOBAL_DETAIL
			: RouteName.CLIENT_DETAIL;
		const route = {
			params: this.$route.params,
			name: routeName,
		} as RawLocation;
		this.$router.push(route);
	}

	navigateToEdit(): void {
		let routeName = this.isGlobal
			? RouteName.GLOBAL_EDIT
			: RouteName.CLIENT_EDIT;
		this.$router.push({ name: routeName, params: this.$route.params });
	}

	generateSaveActionPromise(): Promise<void> {
		const saved = new Alert(
			`${this.asset.fileName} is successfully saved.`,
			NoticeClass.SUCCESS
		);
		saved.setTimeout(6000);

		return new Promise((resolve, reject) => {
			if (this.modeAdd) {
				this.assetSvc.create(this.clientId, this.asset)
					.then((resp) => {
						this.asset = resp.data.data;
						this.reset();
						let routeName = this.isGlobal ? RouteName.GLOBAL_DETAIL : RouteName.CLIENT_DETAIL;
						this.$router.push({ name: routeName, params: { clientId: this.$route.params.clientId, assetId: (this.asset.id as number).toString() }});
						resolve();
					})
					.catch(() =>  reject());

			} else {
				this.assetSvc
					.update(this.clientId, this.assetId, this.asset)
					.then((resp) => {
						this.asset = resp.data.data;
						this.$store.dispatch("notices/add", saved);

						// reset all the vuelidates;
						this.reset();
						resolve();
					})
					.catch((e) => {
						// no need to listen for 401, 403 and 500
						if (e.response.status === 404) {
							// throw an Alert notice?
							const notFound = new Alert(
								`Asset "${this.$route.params.id}" is not found. Go back and try again.`,
								NoticeClass.ERROR
							);
							this.$store.dispatch("notices/add", notFound);
						}
						reject();
					});
			}
		});
	}

	saveAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}

		return this.generateSaveActionPromise();
	}

	saveCloseAction(): Promise<void> | void {
		if (this.checkForErrors()) {
			return;
		}

		return this.generateSaveActionPromise().then(() => {
			this.goToDetails();
		});
	}

	checkForErrors(): boolean {
		let errors = false;

		this.assetForm.touch();

		errors = this.assetForm.hasErrors();
		if (this.modeAdd && !errors) {
			/* eslint-disable-next-line */ //how to cast to v-form?
			errors = !(this.$refs.form as any).validate() || !!this.fileUploadErrorMessages.length;
		}

		if (errors) {
			const broken = new Alert(
				"Unable to save. Please make sure all required fields are completed without errors.",
				NoticeClass.ERROR
			);
			this.$store.dispatch("notices/add", broken);
		}

		return errors;
	}

	checkForDirty(): boolean {
		return this.assetForm.isDirty() || this.isDirty;
	}

	async beforeRouteLeave(
		to: Route,
		from: Route,
		next: NavigationGuardNext
	): Promise<void> {
		if (this.mode == Mode.DETAILS) {
			next();
			return;
		}
		if (this.checkForDirty()) {
			const dialog = new Dialog(
				"Unsaved Changes",
				"You have unsaved changes on this page that will be lost if you leave now. Are you sure?",
				"LEAVE WITHOUT SAVING"
			);
			dialog.setDeclineLabel("STAY ON THIS PAGE").setDismissable(false);

			const res = await this.$store.dispatch("notices/add", dialog);
			switch (res) {
				case NoticeResponse.ACCEPT:
					this.reset();
					next();
					break;
				case NoticeResponse.DECLINE:
				default:
					// staying on the page
					break;
			}
		} else {
			this.reset();
			next();
		}
	}

	reset(): void {
		this.setLoadedData(this.asset);
		this.assetForm.reset();
	}
}

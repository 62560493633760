import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { KSelectFi } from '@kasasa/fbase-components';
import RouterView from '@/components/RouterView.vue';
import ListAssetsPage from '@/pages/ListAssetsPage.vue';
import AssetDetailsPage from '@/pages/AssetDetailsPage.vue';
import { GlobalWorkspaceRoutes, NonGlobalWorkspaceRoutes } from '@kasasa/fbase-components/lib';


Vue.use(VueRouter);

const mergeMeta = (route: Route): Dictionary<string> => {
	return { ...route.params, ...route.meta };
};

export const RouteName = {
	GLOBAL_HOME: 'GlobalHome',
	GLOBAL_ADD: 'GlobalAdd',
	GLOBAL_DETAIL: 'GlobalDetail',
	GLOBAL_EDIT: 'GlobalEdit',
	HOME: 'Home',
	CLIENT_HOME: 'ClientHome',
	CLIENT_ADD: 'ClientAdd',
	CLIENT_DETAIL: 'ClientDetail',
	CLIENT_EDIT: 'ClientEdit',
};

export const Mode = {
	EDIT: 'edit',
	DETAILS: 'details',
	ADD: 'add',
};

const routes = [
	GlobalWorkspaceRoutes([
		{
			path: '/global',
			component: RouterView,
			meta: {
				showSelect: false,
				isGlobal: true,
			},
			props: mergeMeta,
			children: [
				{
					path: '',
					component: ListAssetsPage,
					name: RouteName.GLOBAL_HOME,
					props: mergeMeta,
					meta: {
						showAdd: true,
						isGlobal: true,
						clientId: 0,
					},
				},
				{
					path: ':assetId',
					name: RouteName.GLOBAL_DETAIL,
					component: AssetDetailsPage,
					props: mergeMeta,
					meta: {
						mode: Mode.DETAILS,
						clientId: 0,
						isGlobal: true,
					}
				},
				{
					path: ':assetId/edit',
					name: RouteName.GLOBAL_EDIT,
					component: AssetDetailsPage,
					props: mergeMeta,
					meta: {
						mode: Mode.EDIT,
						clientId: 0,
						isGlobal: true,
					}
				},
				{
					path: 'add',
					name: RouteName.GLOBAL_ADD,
					component: AssetDetailsPage,
					props: mergeMeta,
					meta: {
						mode: Mode.ADD,
						clientId: 0,
						isGlobal: true,
					},
				},
			],
		}
	]),
	NonGlobalWorkspaceRoutes([{
		path: '/',
		name: RouteName.HOME,
		component: KSelectFi
	},	
	{
		path: '/:clientId([1-9]\\d*)',
		component: RouterView,
		children: [
			{
				path: '',
				name: RouteName.CLIENT_HOME,
				component: ListAssetsPage,
				props: mergeMeta,
				meta: {
					showAdd: true
				},
			},
			{
				path: 'asset/:assetId',
				name: RouteName.CLIENT_DETAIL,
				component: AssetDetailsPage,
				props: mergeMeta,
				meta: {
					mode: Mode.DETAILS,
				},
			},	
			{
				path: 'asset/:assetId/edit',
				name: RouteName.CLIENT_EDIT,
				component: AssetDetailsPage,
				props: mergeMeta,
				meta: {
					mode: Mode.EDIT,
				},
			},
			{
				path: 'add',
				name: RouteName.CLIENT_ADD,
				component: AssetDetailsPage,
				props: mergeMeta,
				meta: {
					mode: Mode.ADD,
				},
			},
		],
	},
	{
		path: '*',
		redirect: { name: RouteName.HOME }
	}
	]),	
	
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;




























































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Asset } from '@/services/api';
import { DataTableHeader } from 'vuetify';
import { KScrollBtn } from '@kasasa/fbase-components';
import { searchHighlight } from '@kasasa/fbase-components/lib';
import { KCardHeaderBtn } from '@kasasa/fbase-components';
import { RouteName } from '@/router';
import { Query } from '@/store/modules/Query';
import { Action, State } from 'vuex-class';


@Component({
	components: {
		KScrollBtn,
		KCardHeaderBtn,
	},
})
export default class AssetListTable extends Vue {
	@Prop() items!: Asset[];
	@Prop() readonly clientId !: number;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@Prop({ default: false }) readonly ro !: boolean;

	@Action('resetQuery') resetQuery!: () => void;
	@State('query') query!: Query;

	showGallery = true;

	created(): void {
		this.resetQuery();		
	}

	get headers(): DataTableHeader[] {
		const dataheaders: DataTableHeader[] = [
			{ text: '', value: 'fullThumbUrl', sortable:false},
			{ text: 'File Name', value: 'fileName' },
			{ text: 'Tags', value: 'tags', sortable:false},			
		];

		return dataheaders;
	}
	
	toggleGallery() : void {
		this.showGallery = !this.showGallery;
	}

	handleClick(row: Asset): void {
		if (!row.id) {
			return;
		}
		let routeName =  RouteName.CLIENT_DETAIL;

		if (this.isGlobal) {
			routeName =  RouteName.GLOBAL_DETAIL;			
		}
		this.$router.push({name:  routeName, params: { clientId: this.clientId.toString(), assetId: row.id.toString() }});
	}

	searchHighlight(value: string | number, querySearch: string): string | number {
		return searchHighlight(value, querySearch);
	}

	customFilter(index: number, search: string, item: Asset): boolean | string {
		const lSearch = search.toLowerCase();
		const tagFound = !!item.tags.find(
			(tag) => {				
				return tag.toLowerCase().indexOf(lSearch) !== -1;
			}
		);		
		return tagFound || (item.fileName && item.fileName.toLowerCase().indexOf(lSearch) !== -1);
	}

	galleryFilter(items: Asset[], search: string) : Asset[] {
		return items.filter(item => this.customFilter(0, search, item));
	}
}

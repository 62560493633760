import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import { Asset } from '@/services/api';

export default class AssetService extends FBaseService {

	findAll(clientId: number): Promise<AxiosResponse<ResponseEnvelopeCollection<Asset>>> {
		return this.apiClient.get(`/api/kcms-media/${clientId}/asset`);
	}

	find(clientId: number, id: number): Promise<AxiosResponse<ResponseEnvelope<Asset>>> {
		return this.apiClient.get(`/api/kcms-media/${clientId}/asset/${id}`);
	}

	create(clientId: number, asset: Asset): Promise<AxiosResponse<ResponseEnvelope<Asset>>> {
		return this.apiClient.post(`/api/kcms-media/${clientId}/asset`, asset);
	}

	update(clientId: number, assetId: number, asset: Asset): Promise<AxiosResponse<ResponseEnvelope<Asset>>> {
		return this.apiClient.put(`/api/kcms-media/${clientId}/asset/${assetId}`, asset);
	}

	delete(clientId: number, assetId: number): Promise<AxiosResponse<ResponseEnvelope<Asset>>> {
		return this.apiClient.delete(`/api/kcms-media/${clientId}/asset/${assetId}`);
	}

}

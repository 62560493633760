import { render, staticRenderFns } from "./AssetForm.vue?vue&type=template&id=5bfc7f74&scoped=true&"
import script from "./AssetForm.vue?vue&type=script&lang=ts&"
export * from "./AssetForm.vue?vue&type=script&lang=ts&"
import style0 from "./AssetForm.vue?vue&type=style&index=0&id=5bfc7f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bfc7f74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCardText,VCol,VContainer,VRow,VSwitch})

export interface Asset {
	id?:          number;
	clientId:     number;
	fileName:     string;
	assetUrl:     string;	
	thumbUrl:     string;
	fullAssetUrl: string;
	fullThumbUrl: string;
	contentType:  string;
	binaryData:   string;
	tags:         Array<string>;
	created:      number;
	updated:      number;
}

export const AssetContentType = {
	BMP: 'image/bmp',
	GIF: 'image/gif',
	JPEG: 'image/jpeg',
	PNG: 'image/png',
	WEBP: 'image/webp',
};

export interface Tag {
	tag: string;
}

export function AssetFactory(clientId: number): Asset {
	return {
		clientId:     clientId,
		fileName:     '',
		assetUrl:     '',
		thumbUrl:     '',
		fullAssetUrl: '',
		fullThumbUrl: '',
		contentType:  '',
		binaryData:   '',
		created:      0,
		updated:      0,
		tags:         [],
	} as Asset;
}



























import Vue from 'vue';
import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';

import { AuthGroupManager } from '@kasasa/fbase-components/lib';
import { KPortalHeader, KAppToolbar, KAppToolbarBtn, KNoticeContainer, KAppToolbarMenu } from '@kasasa/fbase-components';


import { GroupPerms } from './store';
import { RouteName } from './router';
const auth = namespace('auth');


@Component({
	name: 'App',
	components: {
		KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn, KAppToolbarMenu
	},
	computed: {
		...mapState(['auth'])
	}
})
export default class App extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;



	get showAddButton(): boolean {
		return this.$route.meta?.showAdd && this.authManager.canWrite(GroupPerms.MAIN_PERM);
	}

	navigateToAdd(): void {
		let routeName = this.$route.meta?.isGlobal ? RouteName.GLOBAL_ADD : RouteName.CLIENT_ADD;
		this.$router.push({ name: routeName, params: { clientId: this.$route.params.clientId }});
	}
}

import Vue from 'vue';
import Vuex from 'vuex';
import { Query } from '@/store/modules/Query';
import TagStore from '@/store/modules/TagStore';


import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		query: new Query(),
	},
	mutations: {
		RESET_QUERY(state) {
			state.query = new Query();
		}
	},
	actions: {
		resetQuery(context) {
			context.commit('RESET_QUERY');
		},
	},
	modules: {
		auth: AuthStore,
		notices: NoticeStore,
		tags: TagStore,
	},
	plugins: [pendoPlugin]
});

export const enum GroupPerms {
	MAIN_PERM = 'media'
}

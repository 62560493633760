
















import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { KSpinner } from '@kasasa/fbase-components';
import { AuthGroupManager } from '@kasasa/fbase-components/lib';

import AssetListTable from '@/components/AssetListTable.vue';

import AssetService from '@/services/AssetService';

import { Asset } from '@/services/api';
import { GroupPerms } from '@/store';


const auth = namespace('auth');
import { loadingStatus } from '@/utils/loadingStatus';

@Component({
	components: {
		KSpinner,
		AssetListTable,
	},
})
export default class ListAssetsPage extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;

	@Prop() clientId !: number;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	
	loadingStatus = loadingStatus;
	status: loadingStatus = loadingStatus.LOADING;


	assetSvc: AssetService = new AssetService(this.$store);
	assets: Asset[] = [];

	get ro() : boolean{
		return !this.authManager.canWrite(GroupPerms.MAIN_PERM);
	}

	@Watch('$route', { immediate: true, deep: true })
	onRouteChange(): void {
		this.loadAssets();
	}

	loadAssets(): void {
		this.status = loadingStatus.LOADING;
		//Check for global workspace
		if (this.isGlobal) {
			this.clientId = 0;
		}
		this.assets = [];
		this.assetSvc.findAll(this.clientId).then(resp => {
			this.assets = resp?.data?.data as Asset[];
			this.status = this.assets && this.assets.length > 0 ? loadingStatus.LOADED : loadingStatus.EMPTY;
		});
	}

}

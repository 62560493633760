


























































import Vue from 'vue';
import { Component, Prop, VModel } from 'vue-property-decorator';

export interface Item {
  text?: string | number ,
  value?: string | number ,
  disabled?: boolean,
  divider?: boolean,
  header?: string
}

@Component({
	components: {
	},
})
export default class KCombobox  extends Vue  {
	@Prop({ default: []} ) readonly items !: Item[];
	@Prop({ default: ''} ) readonly label !:string;
	@VModel() selectedItems!: Item[];

	get readonly(): boolean {
		return this.isAttrSet(this.$attrs.readonly);
	}

	isAttrSet(value: boolean|string|number): boolean {
		return value === true || value === '';
	}

	search = '';
	remove(item: Item ): void {
		this.selectedItems = this.selectedItems.filter( (tag) => tag.value !== item.value);
	}
}

import '@fortawesome/fontawesome-free/css/all.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@kasasa/fbase-components/dist/fbase-components.css';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'fa',
	}

});

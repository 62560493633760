















































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { Asset, Tag } from '@/services/api';
import { Mode } from '@/router';
import AssetForm from '@/components/form/AssetForm.vue';

import { namespace } from 'vuex-class';
const tags = namespace('tags');


const MAX_IMAGE_HEIGHT = 300;

@Component({
	components: {
		KReadOnly,
		AssetForm,
	},
})
export default class AssetDetails extends Vue {
	@Prop({ default: null }) readonly asset!: Asset;
	@Prop({ default: ''} ) readonly mode !: string;
	@Prop({ default: ''} ) fileName !: string;

	@tags.State tags!: Tag[];
	@tags.Action fetchTags!: () => Promise<Tag[]>;

	assetImage = new Image(); 
	imageLoaded = false;
	maxImageHeight = MAX_IMAGE_HEIGHT;

	async loadImage(): Promise<void> {
		//load the image as Image object to access width and height 
		this.assetImage.src = this.asset.fullAssetUrl;
		this.imageLoaded = false;
		this.assetImage.onload = () => {
			this.imageLoaded = true;
		};
	}

	@Watch('$route', { immediate: true, deep: true })
	onRouteChange(): void {
		this.loadImage();
		this.fetchTags();
	}
	
	get imageWidth(): number | null {
		return this.imageLoaded ? this.assetImage.width : null;
	}

	pad(number: number): string {
		return (number > 9 ? number : '0' + number) + '';
	}

	formatDate(updatedDate: number) : string {		
		const date = new Date(updatedDate*1000);
		const month = this.pad(date.getMonth() + 1);
		const day = this.pad(date.getDate());
		const year = date.getFullYear();

		return `${month}/${day}/${year}`;
	}

	get modeDetails(): boolean {
		return this.mode === Mode.DETAILS;
	}

	get modeAdd(): boolean {
		return this.mode === Mode.ADD;
	}
}

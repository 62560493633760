import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import { Tag } from '@/services/api';

export default class TagService extends FBaseService {

	findSuggestedTags(): Promise<AxiosResponse<ResponseEnvelopeCollection<Tag>>> {
		return this.apiClient.get(`/api/kcms-media/suggestedtag`);
	}

	findClientTags(clientId: number): Promise<AxiosResponse<ResponseEnvelopeCollection<Tag>>> {
		return this.apiClient.get(`/api/kcms-media/${clientId}/tag`);
	}

}

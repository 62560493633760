



































































import Vue from 'vue';
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import { Asset, Tag } from '@/services/api';
import KCombobox, { Item } from '@/components/Input/KCombobox.vue';
import HIRT from '@/components/hirt';
import { RuleDecl } from 'vue/types/options';
import { required } from 'vuelidate/lib/validators';
import { Mode as Modes } from '@/router';

import { namespace } from 'vuex-class';
const tags = namespace('tags');

interface tagOptions {
	text: string;
	value: string;
}

@Component({
	components: {
		KReadOnly,
		KCombobox,
		KText,
	},
})
export default class AssetForm extends Vue implements HIRT {
	@VModel() localAsset!: Asset;
	@Prop({ default: ''} ) readonly mode !: string;
	@Prop({ default: ''} ) readonly fileName !: string;
	@Prop({ default: []} ) readonly clientTags !: Tag[];

	@tags.State tags!: Tag[];
	@tags.Action  fetchTags!: () => Promise<Tag[]>;

	Mode = Modes;
	keepFileName = true;
	newFileName = '';

	created():void {
		this.fetchTags();
	}

	isMounted = false;
	mounted(): void {
		this.isMounted = true;
	}

	hasErrors(): boolean {
		return this.$v?.$anyError;
	}

	isDirty(): boolean {
		return this.$v?.$anyDirty;
	}

	reset(): void {
		this.$v?.$reset();
	}
	
	touch(): void{
		this.$v?.$touch();
	}

	validations(): RuleDecl {
		return {
			localAsset: {
				tags: { required },
			}
		};
	}

	@Watch('localAsset', { immediate: true, deep: true })
	onPropertyChanged(newLocalAsset: Asset): void {
		if (this.keepFileName || !newLocalAsset.fileName.length) {
			this.newFileName = this.getFileName(newLocalAsset.fileName);
		}
	}

	@Watch('newFileName')
	onNewFileNameChange(newName: string): void {
		this.newFileName = newName.replaceAll(' ', '-');
	}

	fileNameSwitch(keepFileName: boolean): void {
		if (keepFileName) {
			this.fileNameUpdate(this.getFileName(this.fileName));
		}
	}

	getFileName(fileName: string): string {
		return fileName.substring(0, fileName.lastIndexOf('.')) || '';
	}

	getFileExtension(fileName: string): string {
		return fileName.substring(fileName.lastIndexOf('.')) || '.ext';
	}

	fileNameUpdate(newName: string): void {
		if (newName.length) {
			this.newFileName = newName;
			this.localAsset.fileName = newName + this.getFileExtension(this.localAsset.fileName);
		}
	}


	get selectedTags(): tagOptions[] {
		let tags= this.localAsset.tags.map(
			(tag) => {
				return {
					text: tag,
					value: tag,
				};
			}
		);
		return tags;
	}

	set selectedTags(options: tagOptions[] ) {
		this.localAsset.tags = options.map(
			(tag) => {
				if(typeof tag == 'string') {
					return tag;
				} else {
					return tag.value;
				}
			}
		);
		this.$v.localAsset.tags?.$touch();
	}

	get tagErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v?.localAsset?.tags?.$invalid && this.$v?.localAsset?.tags?.$dirty) ? 'Tags are required' : null
		) : null;
	} 

	get items(): Item[] {
		let itemOptions: Item[] = [
			{
				header: 'Suggested Tags'
			}
		];
		itemOptions = itemOptions.concat(this.tags.map(
			(tag) => {
				return {
					text: tag.tag,
					value: tag.tag,
				};
			}
		));		
		if (this.clientTags.length  > 0 ) {
			itemOptions.push({divider:true});
			itemOptions.push({header:'Other Tags'});
			itemOptions = itemOptions.concat( this.clientTags
				.map(
					(tag) => {
						return {
							text: tag.tag,
							value: tag.tag,
						};
					}
				));
		}
		
		return itemOptions;
	}

}
